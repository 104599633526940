<template>
  <v-row>
    <v-col cols="8">
      <v-btn-toggle mandatory @change="onChangeServiceType" v-model="serviceTypeSelected">
        <v-btn class="text-capitalize form-border"> All </v-btn>
        <v-btn class="text-capitalize form-border"> Income </v-btn>
        <v-btn class="text-capitalize form-border"> Purchases </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col>
      <div class="d-flex justify-end">
        <v-text-field
          label="Search Services"
          prepend-icon="mdi-magnify"
          clearable
          hide-details
          flat
          solo
          class="mx-2 px-4 form-border"
          dense
        ></v-text-field>
        <v-btn
          text
          outlined
          class="form-border text-capitalize mx-2 btn-active-color"
          large
          @click="onToggleServiceForm"
        >
          Add Service
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="productTableHeader"
        :items="productsData"
        class="elevation-1 v-table-middle-align"
        :single-select="false"
        show-select
        v-model="selectedProducts"
      >
        <template v-slot:item.category="{ item }">
          {{ item.category.join(', ') }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
              >
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
              >
                <v-list-item-title>Duplicate</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
              >
                <v-list-item-title class="text-danger">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <v-alert>No data.</v-alert>
        </template>
      </v-data-table>
    </v-col>
    <service-form :open="showFormDialog" @toggle="onToggleServiceForm"></service-form>
  </v-row>
</template>

<script>
import ServiceForm from '../components/ServiceForm.vue';
export default {
  components: {
    ServiceForm,
  },
  data() {
    return {
      productTableHeader: [
          {
          text: "SKU",
          sortable: false,
          value: "sku",
        },
        { text: "NAME", value: "name" },
        { text: "DESCRIPTION", value: "description" },
        { text: "CATEGORY", value: "category" },
        { text: "SALES PRICE", value: "price" },
        { text: "COST", value: "cost" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      productsData: [{
        id: 1,
        sku: 'UG1234123',
        name: 'Hello Smart Watch - XB29Pro GII2',
        category: ['Electronics', 'Smartwatch'],
        description: 'Lorem ipsum',
        price: 300,
        cost: '$300',
        serviceType: 'Income'
      },
      {
        id: 2,
        sku: 'TSLA14102',
        name: 'Tesla Servicing',
        category: ['Smartwatch'],
        description: 'Lorem ipsum',
        price: 2510,
        cost: '$120',
        serviceType: 'Purchases'
      }],
      selectedProducts: [],
      serviceTypeSelected: '',
      productDataTemp: [],
      showFormDialog: false,
    };
  },
  // For Client testing. remove for api testing
  created() {
    this.productDataTemp = JSON.parse(JSON.stringify(this.productsData));
  },
  methods: {
    onChangeServiceType() {
      let serviceType = '';
      switch(this.serviceTypeSelected) {
        case 1:
          serviceType = 'Income';
        break;
        case 2: 
          serviceType = 'Purchases';
        break;
      }
      // Fetch from API here...
      this.productsData = this.productDataTemp.filter(record => serviceType === '' ? true : record.serviceType === serviceType);
    },
    onToggleServiceForm() {
      this.showFormDialog = !this.showFormDialog;
    }
  }
};
</script>

<style lang="scss" scoped>
$form-color: #0889a0;
$btn-active-color: #0171a1;
$text-muted-color: #6d858f;
$text-danger-color: red;
.form-label {
  color: $form-color;
}

.form-border {
  border: 1px solid;
  border-color: $form-color !important;
}

.btn-active-color:active {
  background-color: $btn-active-color;
  color: #fff;
}

.text-muted {
  color: $text-muted-color;
}

.v-btn.v-item--active.v-btn--active {
  color: $btn-active-color;
}

.text-danger {
  color: $text-danger-color;
}

</style>

<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
</style>