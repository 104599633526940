<template>
  <div class="widgetContainer full-width">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">Products & Services</p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-content>
        <v-container fluid>
					
					<v-toolbar flat>
						<v-tabs class="border" v-model="tab">
							<v-tab>Products</v-tab>
							<v-tab>Services</v-tab>
						</v-tabs>
					</v-toolbar>

					<products v-if="tab === 0" class="pa-2" />
					<services v-if="tab === 1" class="pa-2" />

				</v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Services from '../components/Services.vue';
import Products from '../components/Products.vue';

export default {
  components: {
		Services,
		Products,
	},
  data() {
    return {
      searchText: "",
			tab: '',
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  margin: 0 0 0 223px;
}

.is-mobile {
  .full-width {
    padding: 0 24px 0px 24px;
    margin: 0;
  }
}

.vue-app {
  background: none;
}

.v-tabs {
	border-bottom: 1px solid #ddd;

	.v-tab {
		color: #0889a0;
	}
}

.container {
  background-color: #fff;
}
</style>
<style lang="scss">
.container {
    padding: 0;
  }
</style>