<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="60%"
      origin="top center"
      class="dialog pa-0"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>Add Service</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pt-1">
						<v-form>
							<v-row
								no-gutters
							>
								<v-col
									cols="5"
									class="pl-4"
								>
									<v-card
										class="pa-2 grey lighten-4"
										flat
									>
										<v-radio-group v-model="formData.sku">
											<template v-slot:label>
												<div class="form-label">SKU</div>
											</template>
											<v-radio value="System Generated">
												<template v-slot:label>
													<div>System Generated</div>
												</template>
											</v-radio>
											<v-radio value="Duckduckgo">
												<template v-slot:label>
													<div>Custom Number</div>
												</template>
											</v-radio>
										</v-radio-group>

										<label class="form-label text-uppercase" for="formdata-service-name">Service Name</label>
										<v-text-field
											v-model="formData.serviceName"
											label="Type service name"
											solo
											outlined
											flat
											required
											dense
											id="formdata-service-name"
										></v-text-field>

										<label class="form-label text-uppercase" for="formdata-category">Category</label>
										<v-select
											v-model="formData.category"
											:items="categoryData"
											:rules="[v => !!v || $t('field_required')]"
											id="formdata-category"
											label="Select category"
											solo
											flat
											outlined
											dense
										>
										</v-select>

										<label class="form-label text-uppercase" for="formdata-service-description">Service Description</label>
										<v-textarea
											solo
											label="Type description of the service..."
											outlined
											flat
											id="formdata-service-description"
											rows="3"
										></v-textarea>
										
									</v-card>
								</v-col>
								<v-col
									class="white pr-4 pl-4"
								>
									<v-card
										class="pa-2"
										flat
									>
										<fieldset class="px-5 rounded pb-5">
											<legend class="rounded pa-2 mb-4">
												<v-checkbox
													v-model="formData.hasSalesInformation"
													label="SALES INFORMATION"
													hide-details
													class="mt-0 pt-0"
												></v-checkbox>
											</legend>

											<v-row v-if="formData.hasSalesInformation">
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-sales-price">Sales Price</label>
													<v-text-field
														v-model="formData.salesInformation.price"
														label="Enter Sales Price"
														solo
														outlined
														flat
														required
														dense
														id="formdata-sales-price"
														:disabled="!formData.hasSalesInformation"
														hide-details
													></v-text-field>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-income-account">Income Account</label>
													<v-select
														v-model="formData.salesInformation.incomeAccount"
														:items="incomeAccountData"
														:rules="[v => !!v || $t('field_required')]"
														id="formdata-income-account"
														label="Select Income Account"
														solo
														flat
														outlined
														dense
														:disabled="!formData.hasSalesInformation"
														hide-details
													>
													</v-select>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-tax">Tax</label>
													<v-select
														v-model="formData.salesInformation.tax"
														:items="taxData"
														:rules="[v => !!v || $t('field_required')]"
														id="formdata-tax"
														label="Select Tax"
														solo
														flat
														outlined
														dense
														:disabled="!formData.hasSalesInformation"
														hide-details
													>
													</v-select>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-service-type">Service Type</label>
													<v-select
														v-model="formData.salesInformation.serviceType"
														:items="serviceTypeData"
														:rules="[v => !!v || $t('field_required')]"
														id="formdata-service-type"
														label="Select Service Type"
														solo
														flat
														outlined
														dense
														:disabled="!formData.hasSalesInformation"
														hide-details
													>
													</v-select>
												</v-col>
											</v-row>
										</fieldset>

										<fieldset class="px-5 rounded mt-8">
											<legend class="rounded pa-2 mb-4">
												<v-checkbox
													v-model="formData.hasPurchaseInformation"
													label="PURCHASE INFORMATION"
													hide-details
													class="mt-0 pt-0"
												></v-checkbox>
											</legend>
											<v-row v-if="formData.hasPurchaseInformation">
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-sales-price">Cost</label>
													<v-text-field
														v-model="formData.purchaseInformation.cost"
														label="Enter Cost Price"
														solo
														outlined
														flat
														required
														dense
														id="formdata-sales-price"
														:disabled="!formData.hasPurchaseInformation"
														hide-details
													></v-text-field>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-income-account">Expense Account</label>
													<v-select
														v-model="formData.purchaseInformation.expenseAccount"
														:items="expenseAccountData"
														:rules="[v => !!v || $t('field_required')]"
														id="formdata-income-account"
														label="Select Expense Account"
														solo
														flat
														outlined
														dense
														:disabled="!formData.hasPurchaseInformation"
														hide-details
													>
													</v-select>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-purchase-tax">Tax</label>
													<v-text-field
														v-model="formData.purchaseInformation.tax"
														label="Enter Tax"
														solo
														outlined
														flat
														required
														dense
														id="formdata-purchase-tax"
														:disabled="!formData.hasPurchaseInformation"
														hide-details
													></v-text-field>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-purchase-supplier">Supplier</label>
													<v-select
														v-model="formData.purchaseInformation.supplier"
														:items="supplierData"
														:rules="[v => !!v || $t('field_required')]"
														id="formdata-income-supplier"
														label="Select Supplier"
														solo
														flat
														outlined
														dense
														:disabled="!formData.hasPurchaseInformation"
														hide-details
													>
													</v-select>
												</v-col>
												<v-col cols="6">
													<label class="form-label text-uppercase" for="formdata-purchase-charge">Charge (%)</label>
													<v-text-field
														v-model="formData.purchaseInformation.charge"
														label="Enter Reverse Charge"
														solo
														outlined
														flat
														required
														dense
														id="formdata-purchase-charge"
														:disabled="!formData.hasPurchaseInformation"
													></v-text-field>
												</v-col>
											</v-row>
										</fieldset>
									</v-card>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn color="primary" @click="onSaveForm" class="text-capitalize">{{ $t('save') }} &amp; {{ $t('send') }}</v-btn>
					<v-btn outlined text @click="onSaveForm" class="text-capitalize">{{ $t('save') }} &amp; {{ $t('add_another') }}</v-btn>
					<v-btn outlined text class="text-capitalize" @click="onClose">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open"],
	data() {
		return {
			formData: {
				salesInformation: {},
				purchaseInformation:{},
			},
			categoryData: [],
			incomeAccountData: [],
			taxData: [],
			serviceTypeData: [],
			expenseAccountData: [],
			supplierData: [],
		}
	},
  computed: {
    showDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("toggle", value);
      },
    },
  },

  methods: {
    onClose() {
      this.$emit("toggle");
    },

    onSaveForm() {
      this.$emit("toggle");
    },

  },
};
</script>

<style lang="scss" scoped>
	$form-label: #819FB2;
	$border-color: #21606bab;
	.dialog {
		z-index: 9999;
	}
	.form-label, .v-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	fieldset {
		border: 1px solid $border-color;
		border-radius: 5px 5px 5px 5px;
	}

	fieldset legend {
		border: 1px solid;
		border-color: $border-color;
		background-color: #f5f5f5;
	}
</style>