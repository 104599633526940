<template>
  <v-row>
    <v-col cols="5">
      <v-btn-toggle mandatory>
        <v-btn class="text-capitalize form-border"> All </v-btn>
        <v-btn class="text-capitalize form-border"> Single Unit </v-btn>
        <v-btn class="text-capitalize form-border"> Bundles </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col>
      <div class="d-flex justify-end">
        <v-btn text outlined class="form-border text-capitalize btn-active-color" large>
          <v-icon>mdi-filter-menu-outline</v-icon> Filters
        </v-btn>
        <v-text-field
          label="Search Products..."
          prepend-icon="mdi-magnify"
          clearable
          hide-details
          flat
          solo
          class="mx-2 px-4 form-border"
          dense
        ></v-text-field>
        <v-btn
          text
          outlined
          class="form-border text-capitalize mx-2 btn-active-color"
          large
        >
          Add Product
        </v-btn>
        <v-btn
          text
          outlined
          class="form-border text-capitalize btn-active-color"
          large
        >
          Add Bundle
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="productTableHeader"
        :items="productsData"
        class="elevation-1 v-table-middle-align"
        :single-select="false"
        show-select
        v-model="selectedProducts"
      >
        <template v-slot:item.name="{ item }">
          <v-row class="align-center my-1">
            <v-col cols="3">
              <v-img
                height="90"
                width="80"
                :src="item.img"
                alt="Preview"
              ></v-img>
            </v-col>
            <v-col cols="9">
              <div class="text-subtitle-1 text-truncate">{{ item.name }}</div>
              <span class="text-muted">
                {{ item.category.join(', ') }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small text outlined color="primary">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-alert>No data.</v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      productTableHeader: [
          {
          text: "SKU",
          sortable: false,
          value: "sku",
        },
        { text: "NAME & CATEGORY", value: "name" },
        { text: "CARTON DIMENSION", value: "dimension" },
        { text: "IN EACH CARTON", value: "quantity" },
        { text: "UNIT PRICE", value: "unitPrice" },
        { text: "CUSTOM DUTY", value: "customDuty" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      productsData: [{
        id: 1,
        img: 'https://cdn.pocket-lint.com/r/s/320x/assets/images/158795-smartwatches-news-samsung-brings-fall-detection-gesture-controls-and-more-faces-to-galaxy-watch-4-image1-tlpwkxw87n.jpg?v1',
        sku: 'UG1234123',
        name: 'Hello Smart Watch - XB29Pro GII2',
        category: ['Electronics', 'Smartwatch'],
        dimension: '10 x 14 x 9 cm',
        quantity: '10 Units',
        unitPrice: '$300',
        customDuty: 7.51,
      },
      {
        id: 2,
        img: 'https://cdn.pocket-lint.com/r/s/485x/assets/images/157658-smartwatches-news-vs-samsung-galaxy-watch-4-vs-galaxy-watch-4-classic-what-s-the-rumoured-difference-image2-xsq52bjo97.jpg',
        sku: 'SKU:23212',
        name: 'Dual Smart Watch - XB29Pro GII2',
        category: ['Wearables'],
        dimension: '12 x 13 x 8 cm',
        quantity: '25 Units',
        unitPrice: '$250',
        customDuty: 1.51,
      }],
      selectedProducts: [],
    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
$form-color: #0889a0;
$btn-active-color: #0171a1;
$text-muted-color: #6d858f;
.form-label {
  color: $form-color;
}

.form-border {
  border: 1px solid;
  border-color: $form-color !important;
}

.btn-active-color:active {
  background-color: $btn-active-color;
  color: #fff;
}

.text-muted {
  color: $text-muted-color;
}

.v-btn.v-item--active.v-btn--active {
  color: $btn-active-color;
}

</style>

<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
</style>