var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.onChangeServiceType},model:{value:(_vm.serviceTypeSelected),callback:function ($$v) {_vm.serviceTypeSelected=$$v},expression:"serviceTypeSelected"}},[_c('v-btn',{staticClass:"text-capitalize form-border"},[_vm._v(" All ")]),_c('v-btn',{staticClass:"text-capitalize form-border"},[_vm._v(" Income ")]),_c('v-btn',{staticClass:"text-capitalize form-border"},[_vm._v(" Purchases ")])],1)],1),_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-text-field',{staticClass:"mx-2 px-4 form-border",attrs:{"label":"Search Services","prepend-icon":"mdi-magnify","clearable":"","hide-details":"","flat":"","solo":"","dense":""}}),_c('v-btn',{staticClass:"form-border text-capitalize mx-2 btn-active-color",attrs:{"text":"","outlined":"","large":""},on:{"click":_vm.onToggleServiceForm}},[_vm._v(" Add Service ")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 v-table-middle-align",attrs:{"headers":_vm.productTableHeader,"items":_vm.productsData,"single-select":false,"show-select":""},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.join(', '))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"primary":"","color":"primary","text":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Duplicate")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"text-danger"},[_vm._v("Delete")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',[_vm._v("No data.")])]},proxy:true}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1),_c('service-form',{attrs:{"open":_vm.showFormDialog},on:{"toggle":_vm.onToggleServiceForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }